import React, { useState } from 'react';
import { Alert, IconButton, Box, styled, Tooltip } from '@mui/material';
import { ReactFlowProvider } from 'react-flow-renderer';
import yaml from 'js-yaml';
import { CloseRounded, FullscreenRounded } from '@mui/icons-material';
import CreateTemplateDiagram from '../../../common/Diagram/CreateProjectDiagram';
import Sidebar from '../../../common/Diagram/CreateProjectDiagram/Sidebar';
import Loader from '../../../common/Loader';
import { GalleryIcon } from '../../../icons';

const RightContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bg.main,
  height: '100%',
  padding: 0,
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
  position: 'relative',
  '&.fullscreen': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    backgroundColor: theme.palette.background.default,
  },
}));

const LoaderWrp = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  zIndex: 10,
}));

const DiagramWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
}));

const ToogleBtn = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% - 20px)',
  right: 0,
  width: 40,
  height: 40,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  zIndex: 9,
  borderRight: '0 !important',
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

const RightContentBlock = ({
  isError,
  isIOS,
  handleRight,
  FullscreenBtn,
  fullscreenRight,
  setFullscreenRight,
  isLoading,
  textValue,
  handleChange,
  isFileUpload,
  isFirstLoading,
  isPaste,
  setIsPaste,
  handleClickNode,
  error,
  setTextValue,
  setIsImport,
  setIsError,
  setError,
  nodesDiff,
  setNodesChanges,
  setEdgeNodesChanges,
  isEditable,
}: any) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleSidebarShow = () => {
    setShowSidebar(true);
  };

  const handleSidebarHide = () => {
    setShowSidebar(false);
  };

  const handleErrorSet = (err) => {
    setIsError(true);
    setError(err);
  };

  const handleEdges = (edge, actionType) => {
    if (actionType === 'add')
      setTextValue((prev) => {
        const { source, sourceHandle, target, targetHandle } = edge;
        const newVal = prev.replace(
          'connectors:',
          `connectors:
  ${source} - ${sourceHandle} to ${target} - ${targetHandle}:
    ports:
      - $ref: '#/components/${source}/ports/${sourceHandle}'
      - $ref: '#/components/${target}/ports/${targetHandle}'
    waypoints:
    instruments: {}`,
        );

        return newVal;
      });
    if (actionType === 'remove') {
      setTextValue((prev) => {
        let json;
        try {
          json = yaml.load(prev);
        } catch (err) {
          // @ts-ignore
          setError(err);
          setIsError(true);
        }

        if (!json) return prev;

        const connectors = Object.keys(json.connectors);
        const properties = Object.keys(json);
        const searchableWords = [...connectors, ...properties];

        const positions = searchableWords.map((w) =>
          prev.indexOf(`\n${w}`, prev.indexOf(edge)) === -1
            ? prev.indexOf(`\n  ${w}`, prev.indexOf(edge))
            : prev.indexOf(`\n${w}`, prev.indexOf(edge)),
        );

        positions.sort();
        const correctPosition = positions.find(
          (p) => p - prev.indexOf(edge) > 0,
        );

        const newVal = `${prev.substring(
          0,
          prev.indexOf(edge),
        )}${prev.substring(correctPosition + 3, prev?.length)}`;

        return newVal;
      });
    }
  };

  const handleNodes = (_ev, newNodes, edges) => {
    const nodes = newNodes
      ?.map(({ id, position }) => ({ id, position }))
      ?.filter((n) => !n.id.includes('edgeDot'));

    handleWaypointsNodes(newNodes, edges);

    setTextValue((prev) => {
      let jsonFormat;
      try {
        jsonFormat = yaml.load(prev);
      } catch (err) {
        setIsError(true);
        // @ts-ignore
        setError(err);
      }

      if (jsonFormat && jsonFormat?.components) {
        const localComponents = Object.entries(jsonFormat?.components).map(
          ([key, val]) => ({
            id: key,
            position:
              // @ts-ignore
              val?.render !== undefined
                ? {
                    // @ts-ignore
                    x: val?.render[0],
                    // @ts-ignore
                    y: val?.render[1],
                  }
                : undefined,
          }),
        );
        const newVal = prev;

        const nodesDiffTest = nodesDiff(nodes, localComponents);

        setNodesChanges(nodesDiffTest);

        return newVal;
      }
      return null;
    });
  };

  const handleWaypointsNodes = (newNodes, edges) => {
    const allNodes = newNodes?.map(({ id, position }) => ({ id, position }));
    const nodes = allNodes?.filter((n) => !n.id.includes('edgeDot'));
    const edgeMainNodes = allNodes
      .filter((n) => n.id.includes('edgeDot') && !n.id.includes('_extra'))
      .sort((a, b) => (a.id > b.id ? 1 : 0));

    const newEnges = edges.map(({ id, source, data }) => {
      const foundSource = nodes.find((n) => n.id === source);
      const nWaypoints = edgeMainNodes
        .filter((n) => n.id.includes(id))
        .map(({ position }) => ({
          x: Math.round(position.x - (foundSource?.position?.x || 0)),
          y: Math.round(position.y - (foundSource?.position?.y || 0)),
        }));

      return {
        id,
        prevWaypoints: data?.waypoints || [],
        waypoints: foundSource ? nWaypoints : data?.waypoints,
      };
    });

    const edgesDiff = newEnges.filter(
      (e) =>
        JSON.stringify(e.waypoints || []) !==
        JSON.stringify(
          edges.find((edge) => edge.id === e.id)?.data?.waypoints || [],
        ),
    );

    if (edgesDiff.length && !isFirstLoading && !isLoading) {
      setEdgeNodesChanges(edgesDiff);
    }
  };

  const fullscreenWithSidebar =
    showSidebar && (fullscreenRight || handleRight.active);

  return (
    <RightContent className={fullscreenRight ? 'fullscreen' : ''}>
      {!isError && (
        <Box
          sx={{
            position: 'absolute',
            top: '0.5rem',
            right: fullscreenWithSidebar ? '308px' : '0.5rem',
            zIndex: 9,
          }}
        >
          {!isIOS ? (
            <>
              {handleRight.active ? (
                <FullscreenBtn
                  className="active diagram"
                  onClick={handleRight.exit}
                >
                  <CloseRounded />
                </FullscreenBtn>
              ) : (
                <FullscreenBtn className="diagram" onClick={handleRight.enter}>
                  <FullscreenRounded />
                </FullscreenBtn>
              )}
            </>
          ) : (
            <>
              {fullscreenRight ? (
                <FullscreenBtn
                  className="active diagram"
                  onClick={() => setFullscreenRight(false)}
                >
                  <CloseRounded />
                </FullscreenBtn>
              ) : (
                <FullscreenBtn
                  className="diagram"
                  onClick={() => setFullscreenRight(true)}
                >
                  <FullscreenRounded />
                </FullscreenBtn>
              )}
            </>
          )}
        </Box>
      )}
      {!isError ? (
        <ReactFlowProvider>
          <DiagramWrap>
            {!isLoading ? (
              <CreateTemplateDiagram
                value={textValue}
                handleConfigNameChange={handleChange}
                isEditable={isEditable}
                isReFitView={isFileUpload || isPaste}
                setIsReFitView={setIsPaste}
                isFullscreen={handleRight.active || fullscreenRight}
                onFullscreenEnter={handleRight.enter}
                onFullscreenExit={handleRight.exit}
                withFullscreen={false}
                isInteractive
                toggleGrid={() => {}}
                // @ts-ignore
                handleClick={handleClickNode}
                handleErrorSet={handleErrorSet}
                handleNodes={handleNodes}
                handleEdges={handleEdges}
                handleWaypointsNodes={handleWaypointsNodes}
                setTextValue={setTextValue}
                textValue={textValue}
                setIsImport={setIsImport}
              />
            ) : (
              <Loader />
            )}

            {isFirstLoading && !isLoading && (
              <LoaderWrp>
                <Loader />
              </LoaderWrp>
            )}
            {isEditable && (
              <>
                {showSidebar ? (
                  <Sidebar onHide={handleSidebarHide} />
                ) : (
                  <Tooltip title="Open flairs panel" placement="left">
                    <ToogleBtn onClick={handleSidebarShow} className="outlined">
                      <GalleryIcon />
                    </ToogleBtn>
                  </Tooltip>
                )}
              </>
            )}
          </DiagramWrap>
        </ReactFlowProvider>
      ) : (
        <Box sx={{ p: 2 }}>
          <Alert severity="error">YAML is invalid.</Alert>
          <pre>{error?.message}</pre>
        </Box>
      )}
    </RightContent>
  );
};

export default RightContentBlock;
